import { AnamnesisState, AnamnesisStep, AnamnesisStepName } from '../@types/Patient'

export const canSkipStep = (step: AnamnesisStep): boolean => {
  return step.status === AnamnesisState.COMPLETE || step.status === AnamnesisState.NOT_REQUIRED
}

export const canSkip =
  (step: AnamnesisStepName) =>
  (steps: AnamnesisStep[]): boolean => {
    const anamnesisStep = steps.find((current) => current.step === step)

    if (!anamnesisStep) return true

    return canSkipStep(anamnesisStep)
  }

export const canSkipAnamnesis = canSkip(AnamnesisStepName.ANAMNESIS)
export const canSkipFactoring = canSkip(AnamnesisStepName.FACTORING_AGREEMENT)
