import { Clinic, Optins } from '@dentalux/ui-library-core'

export enum AnamnesisState {
  NOT_REQUIRED = 'NOT_REQUIRED',
  MISSING = 'MISSING',
  EXPIRED = 'EXPIRED',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum AnamnesisStepName {
  ANAMNESIS = 'ANAMNESIS',
  FACTORING_AGREEMENT = 'FACTORING_AGREEMENT',
}

export type AnamnesisStep = {
  step: AnamnesisStepName
  status: AnamnesisState
}

type Patient = {
  referenceId: string
  firstName?: string
  lastName?: string
  gender?: string
  clinic: Clinic
  hasHealthAssistant: boolean
  bookedForDifferentPerson: boolean
  anamnesisState: AnamnesisStep[]
  optins?: Optins
}

export default Patient
